const gonorrhoea = {
  name: 'gonorrhoea',
  labReportName: 'Gonorrhoe [GON]',
  interpretationsSupported: ['negative', 'positive', 'fault'],
  parameters: [
    {
      name: 'gonorrhoea',
      id: 'gonorrhoea',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'gonorrhoea-negative',
        name: 'negative',
        displayInterpretation: 'Negative',
      },
      {
        color: '#f52d0a',
        description: 'gonorrhoea-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'gonorrhoea-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default gonorrhoea;
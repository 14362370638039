import React from 'react';
import { useTranslate } from 'react-admin';

import TestResult from './TestResult';
import Definitions from '../../tests/definitions/index';

import './styles.scss';
import {
  getParameterNameForS24,
  getParametersForS24,
} from '../../utils/helpers/partnerParameters';

const TESTS_WITH_STRING_VALUES = [
  'hiv',
  'luesScreen',
  'antiHCV',
  'hbsAntigen',
  'hepatitisB',
  'antiHBC',
  'fecalHemoglobin',
  'hpvGenotyping',
  'gonorrhoea',
  'chlamydia',
];

const TestResults = ({
  data,
  partnerCode,
  userInfo,
}: {
  data: any;
  partnerCode: string | null;
  userInfo: any;
}) => {
  const translate = useTranslate();
  const isSante24 = partnerCode && partnerCode === 's24';

  return (
    <div>
      <h3 className="title">{translate('testResults.results')}</h3>
      {data.map((result: any) => {
        let testDefinition: any;
        let testName = result.name;
        let testParameters = result.parameters;

        if (isSante24) {
          testName = getParameterNameForS24(result.name);
          testParameters = getParametersForS24(result.parameters, userInfo);
        }

        const isStringValueTest = TESTS_WITH_STRING_VALUES.includes(testName);

        if (isStringValueTest) {
          testDefinition =
            Definitions.stringValues[
              testName as keyof typeof Definitions.stringValues
            ];
        } else {
          testDefinition =
            Definitions.numbericValues[
              testName as keyof typeof Definitions.numbericValues
            ];
        }

        const results = testParameters.map((parameter: any) => (
          <TestResult
            data={result}
            testName={testName}
            testParameter={parameter}
            key={parameter.name}
            testDefinition={testDefinition}
            isStringValueTest={isStringValueTest}
          />
        ));

        return results;
      })}
    </div>
  );
};

export default TestResults;

const chlamydia = {
  name: 'chlamydia',
  labReportName: 'Chlamydia [CHL]',
  interpretationsSupported: ['negative', 'positive', 'fault'],
  parameters: [
    {
      name: 'chlamydia',
      id: 'chlamydia',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'chlamydia-negative',
        name: 'negative',
        displayInterpretation: 'Negative',
      },
      {
        color: '#f52d0a',
        description: 'chlamydia-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'chlamydia-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default chlamydia;
export enum TestNames {
  fatigue = 'Fatigue Check',
  fatigueV2 = 'Fatigue Check',
  fatigueV3 = 'Fatigue Check',
  fatigueV4 = 'Fatigue Check',
  stdPremium = 'STD Premium',
  stdPremiumV2 = 'STD Premium - Easy Sampling',
  healthCheckBasic = 'Health Check Basic',
  healthCheckBasicV2 = 'Health Check Basic',
  healthCheckBasicV3 = 'Health Check Basic',
  healthCheckBasicV4 = 'Health Check Basic',
  healthRiskCheck = 'Health Risk Check',
  healthCheckAthletes = 'Health Check for athletes',
  healthCheckVeganAndVegetarian = 'Health Check for vegans and vegetarians',
  healthCheckVeganAndVegetarianV2 = 'Health Check for vegans and vegetarians',
  b12 = 'Vitamin B12 tracking',
  d = 'Vitamin D tracking',
  // Done
  advancedIron = 'Advanced Iron Test',
  n360 = '360 Nutrient & Vitamin Check',
  n360V2 = '360 Nutrient & Vitamin Check',
  ironV2 = "Iron Check",
  ironV3 = "Iron Check",
  colonCancer = "Colon Cancer Screening",
  hpvCheck = "HPV Check",
  // We are not handling this test yet, no-one is checking results
  basicAllergyAndHayfeverCheck = "Basic Allergy & Hayfever Check",
  stdBasicsFemale = "STD Female Basics",
  stdBasicsMale = "STD Male Basics",
  stdBasicsOral = "STD Oral Basics",
  stdBasicsAnal = "STD Anal Basics",
  allergy360 = "Allergy 360",
  allergyCheckForImmunotherapy = "360° Allergy Check",
};
